<template lang="pug">
section.homeBanner
  
  .homeBanner-photobox: a(href='https://publications.greydoorpublishing.com/The-Agency-Turks-and-Caicos-Magazine-ED1/index.html', target='_blank')
    img.homeBanner-photo(alt, src='@/assets/images/banner.png')
</template>

<script>


  export default {
    components: {
      SvgComponent: () => import('@/components/SvgComponent'),
      DrawLine: () => import('@/components/DrawLine'),
    },
  };
</script>

<style lang="scss">
  .homeBanner {
    display: contents;
    

    &-photobox {
      height: max-content;
      z-index: 2;
      background-color: #EDEDED;
      width: 100%;
      display:flex;
      justify-content: center;

    }
    &-photo {
      width: 800px;
      height: 100%;
      object-fit: contain;
      padding: 75px 0;
      cursor: pointer;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */

      @include xl {
        width: 800px;
      }

      @include large {
        width: 600px;
      }

      @include middle {
        width: 600px;
        padding: 45px 0;   
      }

      @include tablet {
        padding: 45px 0;        
      }
      
      @include phone {
        width:100%;
        object-position: top;
        padding: 24px 16px;
      }

     
    }
  }
</style>
